<template>
  <div class="container join">
    <div class="baseBox">
      <div class="joinForm">
        <p>구분</p>
        <el-radio disabled v-model="userType" label="USER">농장주</el-radio>
        <el-radio disabled v-model="userType" label="EXAMINER">심사원</el-radio>
        <p>
          이름
          <span>*</span>
        </p>
        <input type="text" v-model="username" />
        <div>
          <p>
            휴대전화
            <span>*</span>
          </p>
          <div class="flexB">
            <input type="tel" v-model="newMobileNo" @keyup="removeChar" />
            <div class="cert">
              <button class="line" @click="requestCertNumber">인증번호 받기</button>
            </div>
          </div>
        </div>
        <div v-show="isRequestCertNum">
          <p>
            인증번호
            <span>*</span>
          </p>
          <div class="flexB">
            <input class="tel" type="number" v-model="certNumber" @keyup="removeChar" />
            <div class="cert">
              <button class="line" @click="verifyNumber">인증번호 확인</button>
            </div>
          </div>
        </div>

        <button class="point full" @click="submit()">수정하기</button>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import moment from "moment";
import { updateUser, verifyMobile, createCertNumber, fetchProfile } from "@/api/index";
import { mapState } from "vuex";

export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      certNumber: "",
      isRequestCertNum: false,
      isVerify: false,
      username: "",
      userType: "",
      newMobileNo: "",
      drawer: false,
      direction: "btt",
      currentComponent: null,
    };
  },
  computed: {
    ...mapState(["mobileNo"]),
  },

  created() {
    this.$store.dispatch("SET_PAGE_NAME", "내 정보");
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      fetchProfile().then((res) => {
        if (res.data.status == 200) {
          this.username = res.data.data.username;
          this.userType = res.data.data.userType;
          this.newMobileNo = res.data.data.mobileNo;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    requestCertNumber() {
      if (this.mobileNo == this.newMobileNo) {
        this.openAlert("기존 휴대전화 번호와 동일한 번호입니다.");
        return;
      } else if (this.mobileNumberCheck(this.newMobileNo) == false) {
        this.openAlert("휴대폰번호 형식이 맞지 않습니다.");
        return;
      }
      let data = {
        mobileNo: this.newMobileNo,
      };
      this.isRequestCertNum = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          this.openAlert("모바일을 통해서 인증번호를 확인 후 인증번호를 입력해주세요.");
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    verifyNumber() {
      if (this.certNumber == "") {
        this.openAlert("인증 번호를 입력해 주세요.");
        return;
      }
      let data = {
        mobileNo: this.newMobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.openAlert("정상적으로 인증이 완료 되었습니다.");
          this.isVerify = true;
        } else {
          this.openAlert(res.data.message);
          this.isVerify = false;
        }
      });
    },
    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },

    submit() {
      if (this.mobileNo != this.newMobileNo && this.isVerify == false) {
        this.openAlert("휴대폰 인증을 해주세요.");
        return;
      }

      let data = {
        mobileNo: this.newMobileNo,
        username: this.username,
      };
      updateUser(data).then((res) => {
        if (res.data.status == 200) {
          this.openAlert("회원정보가 수정 되었습니다.");
          this.$store.dispatch("getAccountInfo");
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
